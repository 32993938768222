@font-face {
    font-family: 'Greycliff CF';
    src: url('GreycliffCF-Heavy.eot');
    src: local('Greycliff CF Heavy'), local('GreycliffCF-Heavy'),
        url('GreycliffCF-Heavy.eot?#iefix') format('embedded-opentype'),
        url('GreycliffCF-Heavy.woff2') format('woff2'),
        url('GreycliffCF-Heavy.woff') format('woff'),
        url('GreycliffCF-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff CF Extra';
    src: url('GreycliffCF-ExtraBold.eot');
    src: local('Greycliff CF Extra Bold'), local('GreycliffCF-ExtraBold'),
        url('GreycliffCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('GreycliffCF-ExtraBold.woff2') format('woff2'),
        url('GreycliffCF-ExtraBold.woff') format('woff'),
        url('GreycliffCF-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('GreycliffCF-Medium.eot');
    src: local('Greycliff CF Medium'), local('GreycliffCF-Medium'),
        url('GreycliffCF-Medium.eot?#iefix') format('embedded-opentype'),
        url('GreycliffCF-Medium.woff2') format('woff2'),
        url('GreycliffCF-Medium.woff') format('woff'),
        url('GreycliffCF-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('GreycliffCF-Regular.eot');
    src: local('Greycliff CF'), local('GreycliffCF-Regular'),
        url('GreycliffCF-Regular.eot?#iefix') format('embedded-opentype'),
        url('GreycliffCF-Regular.woff2') format('woff2'),
        url('GreycliffCF-Regular.woff') format('woff'),
        url('GreycliffCF-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


