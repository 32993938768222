@font-face {
  font-family: Greycliff CF;
  src: url("../fonts/GreycliffCF-Heavy.eot");
  src: local(Greycliff CF Heavy), local(GreycliffCF-Heavy), url("../fonts/GreycliffCF-Heavy.eot#iefix") format("embedded-opentype"), url("../fonts/GreycliffCF-Heavy.woff2") format("woff2"), url("../fonts/GreycliffCF-Heavy.woff") format("woff"), url("../fonts/GreycliffCF-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Greycliff CF Extra;
  src: url("../fonts/GreycliffCF-ExtraBold.eot");
  src: local(Greycliff CF Extra Bold), local(GreycliffCF-ExtraBold), url("../fonts/GreycliffCF-ExtraBold.eot#iefix") format("embedded-opentype"), url("../fonts/GreycliffCF-ExtraBold.woff2") format("woff2"), url("../fonts/GreycliffCF-ExtraBold.woff") format("woff"), url("../fonts/GreycliffCF-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Greycliff CF;
  src: url("../fonts/GreycliffCF-Medium.eot");
  src: local(Greycliff CF Medium), local(GreycliffCF-Medium), url("../fonts/GreycliffCF-Medium.eot#iefix") format("embedded-opentype"), url("../fonts/GreycliffCF-Medium.woff2") format("woff2"), url("../fonts/GreycliffCF-Medium.woff") format("woff"), url("../fonts/GreycliffCF-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Greycliff CF;
  src: url("../fonts/GreycliffCF-Regular.eot");
  src: local(Greycliff CF), local(GreycliffCF-Regular), url("../fonts/GreycliffCF-Regular.eot#iefix") format("embedded-opentype"), url("../fonts/GreycliffCF-Regular.woff2") format("woff2"), url("../fonts/GreycliffCF-Regular.woff") format("woff"), url("../fonts/GreycliffCF-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes rpgu6a_opacity {
  0% {
    opacity: 1;
    transform: translate(-50%);
  }

  100% {
    opacity: .5;
    transform: translate(-50%, 3px);
  }
}

.rpgu6a_cardExpendIcon {
  cursor: pointer;
  z-index: 2;
  transition: all .5s ease-in-out;
  animation: .5s linear infinite alternate rpgu6a_opacity;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.rpgu6a_cardExpendIconOpened {
  bottom: 0;
}

/*# sourceMappingURL=index.aa4b99e4.css.map */
